import React, { useState, useEffect, useRef } from "react";
import layout from "./assets/layout.jpg";
import {
  useAcTempSensorMapMutation,
  useGetBranchWiseAcListQuery,
} from "../../../features/ac/acBranchWiseAcDashboard";
import { useAcSensorMapMutation } from "../../../features/ac/acBranchWiseAcDashboard";

import { useGetAcSensorListQuery } from "../../../features/ac/acBranchWiseAcDashboard";
import useTitle from "../../../hooks/useTitle";
import "./acSensorMap.css";

import { useGetAcBranchListQuery } from "../../../features/ac/acBranchCreateApi";
import timeAgo from "../../../helper/timeAgo";

function AcSensorMap() {
  useTitle("Sensor Map");
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const layoutRef = useRef(null);
  const [acs, setAcs] = useState([]);
  const [sensors, setSensors] = useState([]);
  const [draggingItem, setDraggingItem] = useState(null); // Can be AC or sensor
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [draggingType, setDraggingType] = useState(null); // Tracks whether dragging an AC or sensor
  const [selectedBranchId, setSelectedBranchId] = useState(null);
  const [imageUrl, setImageUrl] = useState("");

  const { data: branchWiseAcList, isLoading } = useGetBranchWiseAcListQuery(
    selectedBranchId,
    { skip: !selectedBranchId } // Skip query if branch ID is not selected
  );

  const { data: branchWiseSensorList, isLoading: sensorListLoading } =
    useGetAcSensorListQuery(
      selectedBranchId,
      { skip: !selectedBranchId } // Skip query if branch ID is not selected
    );

  const handleBranchChange = (event) => {
    const branchId = event.target.value;
    setSelectedBranchId(branchId); // Update the branch ID based on user selection
    const selectedBranch = acBranchList?.data?.find(
      (branch) => branch.id == branchId
    );
    const imageUrl = selectedBranch?.image || ""; // Use the branch image or default to an empty string
    setImageUrl(imageUrl);
  };

  const [acSensorMap] = useAcSensorMapMutation();
  const [acTempSensorMap] = useAcTempSensorMapMutation(); // To update sensor position

  const { data: acBranchList, isLoading: branchListLoading } =
    useGetAcBranchListQuery();
  // Fetch AC and sensor data when loading completes

  useEffect(() => {
    if (imageUrl) {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        setImageSize({ width: img.width, height: img.height });
      };
    }
  }, [imageUrl]);
  let latestTimeStamp = "";
  let time_Ago = "";

  if (selectedBranchId) {
    latestTimeStamp = new Date(branchWiseSensorList?.[0]?.updated_at);

    if (latestTimeStamp) {
      time_Ago = timeAgo(latestTimeStamp);
    }
    console.log(time_Ago);
    console.log(branchWiseSensorList?.[0]?.updated_at || "No data");
  }

  useEffect(() => {
    // Fetch and set AC data
    if (!isLoading && branchWiseAcList) {
      const acsWithCoordinates = branchWiseAcList?.map((ac) => ({
        ...ac,
        x: ac.x ?? 100, // Default x position if null
        y: ac.y ?? 100, // Default y position if null
      }));
      setAcs(acsWithCoordinates);
    }

    // Fetch and set sensor data
    if (!sensorListLoading && branchWiseSensorList) {
      const sensorsWithCoordinates = branchWiseSensorList?.map((sensor) => ({
        ...sensor,
        x: sensor.x ?? 200, // Default x position if null
        y: sensor.y ?? 200, // Default y position if null
      }));
      setSensors(sensorsWithCoordinates);
    }
  }, [isLoading, sensorListLoading, branchWiseAcList, branchWiseSensorList]);

  // Handle dragging start
  const handleMouseDown = (type, index, e) => {
    setDraggingItem(index);
    setDraggingType(type); // Track whether dragging an AC or sensor
    if (type === "AC") {
      setStartPos({
        x: e.clientX - acs[index].x,
        y: e.clientY - acs[index].y,
      });
    } else if (type === "sensor") {
      setStartPos({
        x: e.clientX - sensors[index].x,
        y: e.clientY - sensors[index].y,
      });
    }
  };

  // Handle dragging movement
  const handleMouseMove = (e) => {
    if (draggingItem === null) return;

    const newX = e.clientX - startPos.x;
    const newY = e.clientY - startPos.y;

    if (draggingType === "AC") {
      setAcs((prevAcs) =>
        prevAcs.map((ac, i) =>
          i === draggingItem ? { ...ac, x: newX, y: newY } : ac
        )
      );
    } else if (draggingType === "sensor") {
      setSensors((prevSensors) =>
        prevSensors.map((sensor, i) =>
          i === draggingItem ? { ...sensor, x: newX, y: newY } : sensor
        )
      );
    }
  };

  // Handle drag end and save position
  const handleMouseUp = async () => {
    if (draggingItem === null) return;

    if (draggingType === "AC") {
      const draggedAc = acs[draggingItem];

      // Save the updated position (x, y) for AC
      acSensorMap({
        id: draggedAc.peripheral_unit_id,
        x: draggedAc.x,
        y: draggedAc.y,
      });
    } else if (draggingType === "sensor") {
      const draggedSensor = sensors[draggingItem];

      // Save the updated position (x, y) for sensor
      acTempSensorMap({
        id: draggedSensor.peripheral_unit_id,
        x: draggedSensor.x,
        y: draggedSensor.y,
      });
    }

    setDraggingItem(null); // Stop dragging
    setDraggingType(null); // Reset dragging type
  };

  // const getHeatColor = (temp) => {
  //   if (temp <= 20) return "rgba(0, 0, 255, 0.3)"; // Cool: Blue
  //   if (temp <= 35) return "rgba(0, 255, 0, 0.3)"; // Medium: Green
  //   return "rgba(255, 0, 0, 0.3)"; // Hot: Red
  // };
  const getHeatMapStyle = (temp) => {
    if (temp <= 28) {
      return {
        boxShadow:
          "0 0 30px 10px rgba(0, 255, 0, 0.3), 0 0 60px 30px rgba(0, 255, 0, 0.2)",
        animation: "green-glow 2s infinite",
      }; // Medium: Green with gradient-like shadow
    } else if (temp <= 30) {
      return {
        boxShadow:
          "0 0 30px 10px rgba(243, 243, 10, 0.317), 0 0 60px 30px rgba(248, 248, 6, 0.2)",
        animation: "yellow-glow 2s infinite",
      }; // Cool: Yellow with gradient-like shadow
    } else {
      return {
        boxShadow:
          "0 0 30px 10px rgba(255, 0, 0, 0.3), 0 0 60px 30px rgba(255, 0, 0, 0.2)",
        animation: "red-glow 2s infinite",
      }; // Hot: Red with gradient-like shadow
    }
  };

  return (
    <div>
      <div className="card theme-background">
        <div
          className="theme-text"
          style={{ textAlign: "center", padding: "10px", fontSize: "18px" }}
        >
          {/* Updated at: {time_Ago || "No data"} */}
          Ac Sensor Map
        </div>
      </div>
      <div
        className="col-md-3 date-search-submit-btn "
        style={{ marginLeft: "auto", paddingBottom: "20px" }}
      >
        <select
          className="form-select"
          aria-label="Default select example"
          // value={sensor_id}
          onChange={(e) => handleBranchChange(e)}
        >
          <option value="" selected>
            Select Branch
          </option>
          {acBranchList?.data?.map((branchList) => {
            return <option value={branchList.id}>{branchList.name}</option>;
          })}
        </select>
      </div>{" "}
      <div
        className="App"
        style={{
          width: `${imageSize.width}px`,
          height: `${imageSize.height}px`,
          position: "relative",
          // overflow: "hidden",
          // backgroundImage: `url(${layout})`,
          backgroundImage: `url(${imageUrl})`,

          backgroundSize: "auto",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        ref={layoutRef}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        {/* Render ACs dynamically */}
        {acs.map((ac, index) => (
          <div>
            <div
              key={ac?.peripheral_unit_id}
              onMouseDown={(e) => handleMouseDown("AC", index, e)}
              style={{
                width: "60px",
                height: "60px",
                color: "white",
                backgroundColor: ac?.status == "on" ? "#2f8034" : "#d14b36",
                border: "2px solid black",
                borderRadius: "50%",
                position: "absolute",
                left: `${ac.x}px`,
                top: `${ac.y}px`,
                cursor: "grab",
                zIndex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                animation:
                  ac?.status == "on" ? "ac-on-heartbeat 2s infinite" : "none",
              }}
            >
              <div
                style={{
                  width: "30px",
                  height: "30px",
                  fontWeight: "bold",
                  position: "absolute",
                  // left: `${ac.x}px`,
                  // top: `${ac.y + 35}px`,
                  top: "0px",
                  zIndex: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // Add the heat map effect
                }}
              >
                AC:{ac?.peripheral_number}
              </div>
              <div
                style={{
                  width: "30px",
                  height: "30px",
                  fontWeight: "bold",
                  position: "absolute",
                  // left: `${ac.x}px`,
                  // top: `${ac.y + 35}px`,
                  top: "20px",
                  zIndex: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // Add the heat map effect
                }}
              >
                <div>{ac?.temp !== null ? `${ac?.temp}°C` : "N/A"}</div>
              </div>
            </div>
          </div>
        ))}
        {/* Render Sensors dynamically */}
        {sensors.map((sensor, index) => (
          <div key={sensor.peripheral_unit_id}>
            <div
              onMouseDown={(e) => handleMouseDown("sensor", index, e)}
              style={{
                width: "60px", // Adjust sensor size as needed
                height: "60px",
                backgroundColor: "#fff", // Keep the base color neutral
                border: "2px solid black",
                borderRadius: "50%",
                position: "absolute",
                left: `${sensor.x}px`,
                top: `${sensor.y}px`,
                cursor: "grab",
                zIndex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                ...getHeatMapStyle(sensor.sensor_value), // Add the heat map effect
              }}
            >
              <div
                style={{
                  width: "30px",
                  height: "30px",
                  fontWeight: "bold",
                  position: "absolute",
                  // left: `${sensor.x}px`,
                  // top: `${sensor.y + 25}px`,
                  top: "0px",
                  zIndex: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // Add the heat map effect
                }}
              >
                S:{sensor.slave_number}
              </div>
              <div
                style={{
                  width: "30px",
                  height: "30px",
                  fontWeight: "bold",
                  position: "absolute",
                  // left: `${sensor.x}px`,
                  // top: `${sensor.y + 25}px`,
                  top: "20px",
                  zIndex: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // Add the heat map effect
                }}
              >
                {/* {sensor.sensor_value}°C */}
                {sensor.sensor_value !== null
                  ? `${sensor.sensor_value}°C`
                  : "N/A"}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AcSensorMap;
