import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaEdit, FaImage } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { ThemeContext } from "../../context/ThemeContext";
import { Table, Pagination } from "rsuite";

import { useGetAllPremisesQuery } from "../../features/premises/premisesApi";

import { useGetAllRoomsQuery } from "../../features/rooms/roomsApi";
import { useGetAllBuildingsQuery } from "../../features/building/buildingApi";
import { useRoomStoreMutation } from "../../features/rooms/roomsApi";
import { useUpdateRoomInfoMutation } from "../../features/rooms/roomsApi";

import getBase64 from "../../helper/helper";
import useTitle from "../../hooks/useTitle";
// import "./styles/premises.css";
const { Column, HeaderCell, Cell } = Table;

function Rooms() {
  useTitle("Create Room");
  const theme = React.useContext(ThemeContext);
  const [show, setShow] = React.useState(false);
  const [createFormSelector, setCreateFormSelector] = useState(true);
  const [reseponseMessage, setResponseMessage] = useState();
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [updateFormData, setUpdateFormData] = useState({});
  const [premisesList, setPremisesList] = useState([]);
  const [buildingList, setBuildingList] = useState([]);

  const { data: allPremisesData, isLoading, error } = useGetAllPremisesQuery();
  const { data: allBuildingList, isLoading: BuildingListLoading } =
    useGetAllBuildingsQuery();

  const { data: allRoomsList, isLoading: roomsListLoading } =
    useGetAllRoomsQuery();

  const [roomStore, result] = useRoomStoreMutation();
  const [updateRoomInfo] = useUpdateRoomInfoMutation();

  useEffect(() => {
    if (!isLoading) {
      if (allPremisesData.success) {
        setPremisesList(allPremisesData.data);
      }
    }
    if (!BuildingListLoading) {
      if (allBuildingList.success) {
        setBuildingList(allBuildingList.data);
      }
    }
  }, [isLoading, BuildingListLoading]);

  let defaultData = [];
  let data = [];
  if (!roomsListLoading) {
    // console.log(allPremisesData);
    // console.log(allRoomsList);
    if (allRoomsList) {
      defaultData = [...allRoomsList.data];
      defaultData = defaultData?.map((premesisInfo) => {
        const data = new Date(premesisInfo.updated_at);
        const formattedDate = data.toLocaleString();
        return {
          ...premesisInfo,
          updated_at: formattedDate,
        };
      });
    }

    data = defaultData?.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
  }

  const [imageShow, setImageShow] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [file, setFile] = useState(null);

  //   if (!isLoading) {
  //     // console.log(allPremisesData);
  //     if (allPremisesData) {
  //       defaultData = [...allPremisesData];
  //       defaultData = defaultData?.map((premesisInfo) => {
  //         const data = new Date(premesisInfo.updated_at);
  //         const formattedDate = data.toLocaleString();
  //         return {
  //           ...premesisInfo,
  //           updated_at: formattedDate,
  //         };
  //       });
  //     }

  //     data = defaultData?.filter((v, i) => {
  //       const start = limit * (page - 1);
  //       const end = start + limit;
  //       return i >= start && i < end;
  //     });
  //   }

  // const handleChange = (e) => {
  //   alert(e.target.name);

  //   setFile(e.target.files[0]);
  //   if (file) {
  //     setImageUrl(URL.createObjectURL(file));
  //   } else {
  //     setImageUrl(null);
  //   }
  // };

  // if (updatePremisesResult || result) {
  //   console.log(updatePremisesResult || result);
  // }

  const handleClose = () => {
    setShow(false);
    setImageShow(false);
  };
  const handleModalOutsideClick = () => {
    setShow(false);
    setResponseMessage("");
  };
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();

    if (createFormSelector) {
      //   alert("Create Form");
      console.log(JSON.stringify(updateFormData));
      roomStore(updateFormData)
        .unwrap()
        .then((payload) => setResponseMessage(payload?.message))
        .catch((error) => setResponseMessage(error?.status));
    } else {
      //   alert("Update Form");
      console.log(JSON.stringify(updateFormData));
      updateRoomInfo(updateFormData)
        .unwrap()
        .then((payload) => setResponseMessage(payload?.message))
        .catch((error) => setResponseMessage(error?.status));
      // updatePremisesInfo({
      //   id: 63,
      //   name: "Padmaa",
      //   country_id: "2",
      //   address: "Khustia1",
      //   area_size: "1000 sqrs",
      //   lat: "1001",
      //   long:"1003",
      //   area_map: null,
      //   building_num: "5",
      // });
    }
  };
  const handleEdit = (id) => {
    // alert(id);
    let formData = defaultData.find((object) => object.id == id) || {};

    delete formData.map_image; // as updating empty value keeps previous image on db
    setUpdateFormData(formData);
    // console.log(formData);
  };
  const dataChangeHandler = (e) => {
    if (e.target.name === "image") {
      getBase64(e.target.files[0])
        .then((base64image) => {
          setUpdateFormData((previousValue) => ({
            ...previousValue,
            [e.target.name]: base64image,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setUpdateFormData((previousValue) => ({
        ...previousValue,
        [e.target.name]: e.target.value,
      }));
    }
    // console.log(updateFormData);
  };

  const imageShowHandler = (area_map) => {
    console.log(area_map);
  };
  const imageModalOutsideClick = () => {
    setImageShow(false);
  };

  return (
    <div className={`row row-sm ${theme} `}>
      <div
        className="theme-text"
        style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "1rem" }}
      >
        Create Room
      </div>
      <div style={{ textAlign: "right", marginBottom: "1rem" }}>
        <button
          className="btn btn-primary btn-md "
          // style={{ width: "20vw" }}
          onClick={() => {
            setShow(true);
            setCreateFormSelector(true);
            setUpdateFormData({});
          }}
        >
          Add Room
        </button>
        {/* </div> */}
      </div>
      <Modal
        show={imageShow}
        onHide={handleClose}
        className={`${theme} `}
        onExited={imageModalOutsideClick}
        centered
      >
        <div className={`${theme}`}>
          <Modal.Header
            className="theme-background theme-text"
            style={{ borderRadius: "0px" }}
          >
            <Modal.Title>Image</Modal.Title>

            <RxCross2 onClick={imageModalOutsideClick} />
          </Modal.Header>
          <Modal.Body className="theme-background theme-text">
            {" "}
            <img
              className="image_inside_modal"
              src={imageUrl}
              alt="modal_image"
            ></img>
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        classname={`${theme} `}
        centered
        onExited={handleModalOutsideClick}
      >
        <div className={`${theme}`}>
          <Modal.Header
            className="theme-background theme-text"
            style={{ borderRadius: "0px" }}
          >
            <Modal.Title>
              {createFormSelector ? "Add Room" : "Update Room"}
            </Modal.Title>
            <div style={{ color: "green" }}>{reseponseMessage}</div>
            <RxCross2 onClick={handleModalOutsideClick} />
          </Modal.Header>
          <Modal.Body className="theme-background theme-text">
            {" "}
            <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 zero-padding">
              <div>
                <div>
                  <form onSubmit={formSubmitHandler}>
                    <div className="form-group">
                      <label for="premisesName">Room Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Room Name"
                        // value={name}
                        name="room_name"
                        defaultValue={updateFormData?.room_name || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label class="form-label" for="premises no">
                        Select Premises
                      </label>
                      <select
                        required
                        name="premises_id"
                        class="form-control form-select"
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        defaultValue={updateFormData?.premises_id || ""}
                      >
                        <option label="Choose one"></option>
                        {premisesList?.map((premisesInfo) => {
                          return (
                            <option value={premisesInfo?.id}>
                              {premisesInfo?.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div class="form-group">
                      <label class="form-label" for="premises no">
                        Select Building
                      </label>
                      <select
                        required
                        name="building_id"
                        class="form-control form-select"
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        defaultValue={updateFormData?.building_id || ""}
                      >
                        <option label="Choose one"></option>
                        {buildingList?.map((buildingInfo) => {
                          return (
                            <option value={buildingInfo?.id}>
                              {buildingInfo?.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="form-group">
                      <label for="countryName">Level</label>
                      <input
                        type="number"
                        step="any"
                        className="form-control"
                        name="level"
                        placeholder="Level"
                        defaultValue={updateFormData?.level || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label for="address">Location</label>
                      <input
                        type="text"
                        name="location"
                        placeholder="Location"
                        defaultValue={updateFormData?.location || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        className="form-control"
                        required
                      />
                    </div>

                    <div
                      className="input-group theme-background theme-text"
                      style={{ border: "0px" }}
                    >
                      <label
                        for="height"
                        className="form-control theme-background theme-text"
                        style={{ border: "0px", marginLeft: "-0.6rem" }}
                      >
                        Height
                      </label>
                      {/* <span class="input-group-addon">-</span> */}
                      <label
                        for="width"
                        className="form-control theme-background theme-text"
                        style={{ border: "0px", paddingLeft: "2rem" }}
                      >
                        Width
                      </label>
                    </div>
                    <div
                      className="input-group"
                      style={{ paddingBottom: "0.8rem" }}
                    >
                      <input
                        type="number"
                        step="any"
                        className="form-control"
                        placeholder="Height"
                        name="height"
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        defaultValue={updateFormData?.height || ""}
                        required
                      />
                      <span className="input-group-addon">-</span>
                      <input
                        type="number"
                        step="any"
                        className="form-control"
                        placeholder="Width"
                        name="width"
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        defaultValue={updateFormData?.width || ""}
                        required
                      />
                    </div>
                    <div
                      className="input-group theme-background theme-text"
                      style={{ border: "0px" }}
                    >
                      <label
                        for="depth"
                        className="form-control theme-background theme-text"
                        style={{ border: "0px", marginLeft: "-0.6rem" }}
                      >
                        Depth
                      </label>
                      {/* <span class="input-group-addon">-</span> */}
                      <label
                        for="capacity"
                        className="form-control theme-background theme-text"
                        style={{ border: "0px", paddingLeft: "2rem" }}
                      >
                        Capacity
                      </label>
                    </div>
                    <div
                      className="input-group"
                      style={{ paddingBottom: "0.8rem" }}
                    >
                      <input
                        type="number"
                        step="any"
                        className="form-control"
                        placeholder="Depth"
                        name="depth"
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        defaultValue={updateFormData?.depth || ""}
                        required
                      />
                      <span className="input-group-addon">-</span>
                      <input
                        type="number"
                        step="any"
                        className="form-control"
                        placeholder="Capacity"
                        name="capacity"
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        defaultValue={updateFormData?.capacity || ""}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Product Type</label>
                      <input
                        type="text"
                        name="product_type"
                        placeholder="Product Type"
                        defaultValue={updateFormData?.product_type || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Ideal Temperature</label>
                      <input
                        type="number"
                        step="any"
                        name="ideal_temp"
                        placeholder="Ideal Temperature"
                        defaultValue={updateFormData?.ideal_temp || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label for="areaMap">Room Image</label>
                      <input
                        type="file"
                        name="map_image"
                        onChange={(e) => {
                          dataChangeHandler(e);
                          // handleChange(e);
                        }}
                        className="form-control"
                      />
                      {/* <img
                        src={imageUrl ? imageUrl : updateFormData?.area_map}
                      />{" "} */}
                    </div>

                    <div style={{ textAlign: "center" }}>
                      <Button type="submit">Submit</Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <div>
        <div className="table-responsive theme-background theme-text">
          <Table
            height={500}
            data={data || []}
            loading={defaultData.length > 0 ? false : true}
          >
            <Column
              width={50}
              align="center"
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Id.</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            {/* <Column
              width={50}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Device Id </HeaderCell>
              <Cell dataKey="device_id" />
            </Column> */}
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Room Name</HeaderCell>
              <Cell dataKey="room_name" />
            </Column>
            <Column
              width={80}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Product Type</HeaderCell>
              <Cell dataKey="product_type" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Ideal Temp</HeaderCell>
              <Cell dataKey="ideal_temp" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Height</HeaderCell>
              <Cell dataKey="height" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Width</HeaderCell>
              <Cell dataKey="width" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Depth</HeaderCell>
              <Cell dataKey="depth" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Capacity</HeaderCell>
              <Cell dataKey="capacity" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Location</HeaderCell>
              <Cell dataKey="location" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Level</HeaderCell>
              <Cell dataKey="level" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>
                Premises Name
              </HeaderCell>
              <Cell dataKey="premises_name" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>
                Building Name
              </HeaderCell>
              <Cell dataKey="building_name" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Room Image</HeaderCell>
              <Cell dataKey="map_image">
                {(rowData) => (
                  <div
                    icon="edit"
                    onClick={() => {
                      setImageShow(true);
                      setImageUrl(rowData?.map_image);
                      // setImageUrl("https://via.placeholder.com/600/92c952");
                    }}
                  >
                    {/* <FaImage /> */}
                    <img
                      className="premises_map_image"
                      src={rowData?.map_image || ""}
                    ></img>
                  </div>
                )}
              </Cell>
            </Column>

            <Column
              width={200}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Last Updated</HeaderCell>
              <Cell dataKey="updated_at" />
            </Column>
            <Column
              width={200}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Edit</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div
                    icon="edit"
                    onClick={() => {
                      setShow(true);
                      setCreateFormSelector(false);
                      handleEdit(rowData.id);
                    }}
                  >
                    <FaEdit />
                  </div>
                )}
              </Cell>
            </Column>
          </Table>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="lg"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              total={defaultData.length}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Rooms;
