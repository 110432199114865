import { apiSlice2 } from "../../api/apiSlice";

export const acrelDashboardApi = apiSlice2.injectEndpoints({
  tagTypes: ["company"],
  endpoints: (builder) => ({
    getAcrelDashboardData: builder.query({
      query: (meter_id) =>
        `/apiV2/energy-meter/device-status?combined_id=${meter_id}`,
      providesTags: ["company"],
    }),
  }),
});

export const { useGetAcrelDashboardDataQuery } = acrelDashboardApi;
