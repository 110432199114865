import React, { useContext, useEffect } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import useTitle from "../../../hooks/useTitle";
import styles from "./acDashboard.module.css";
import AcCard from "./AcCard";

import { Scale } from "chart.js";
import { useLocation, useParams } from "react-router-dom";
import { useGetBranchWiseAcListQuery } from "../../../features/ac/acBranchWiseAcDashboard";
import { useLazyGetBranchWiseAcListQuery } from "../../../features/ac/acBranchWiseAcDashboard";

import mqtt from "mqtt/dist/mqtt";

function AcDashboard() {
  useTitle("Ac Dashboard");
  const theme = useContext(ThemeContext);
  const { branchId } = useParams();
  const location = useLocation();

  const searchParams = new URLSearchParams(location?.search);

  // Get individual search params
  const branchName = searchParams.get("branch_name");

  let { data: branchWiseAcList, isLoading } =
    useGetBranchWiseAcListQuery(branchId);
  const [getBranchWiseAcList, results] = useLazyGetBranchWiseAcListQuery();

  // if (!isLoading) {
  //   console.log(branchWiseAcList);
  // }
  // //mqtt code starts
  let options = {
    clientId: Math.random(),
    username: "shahed",
    password: "Aqualink_321",
    // clean: true,
    reconnectPeriod: 1000,
    port: 8082,
  };

  // let client = mqtt.connect("mqtt://mqtt.aqualinkbd.com", options);
  let client = mqtt.connect("mqtts://mqtt.aqualinkbd.com", options);
  // console.log(client);
  useEffect(() => {
    client.subscribe("ac/dashboard/");

    // Update the document title using the browser API
    client.on("message", (topic, message) => {
      console.log(topic, message.toString());
      // getDashboardData();
      getBranchWiseAcList(branchId);
    });
    return () => {
      client.end(); // Clean up to prevent resource leaks
    };
  }, [client]);
  // // mqtt code ends

  let element = (
    <div className="row ">
      {branchWiseAcList?.map((acInfo) => {
        return (
          <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3">
            <AcCard acInfo={acInfo} />
          </div>
        );
      })}
    </div>
  );

  if (!isLoading) {
    console.log(results.data);
    if (results?.data) {
      branchWiseAcList = results?.data;
    }
    element = (
      <div className="row ">
        {branchWiseAcList?.map((acInfo) => {
          return (
            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <AcCard acInfo={acInfo} />
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div>
      <div className={`container-fluid ${theme}`}>
        <div className="card theme-background">
          <div
            className={`theme-text ${styles.top_bar}`}
            style={{ padding: "10px", fontSize: "18px" }}
          >
            <div>{branchName}</div>
            {/* <div>Last Updated : 3 Minuites ago</div> */}
            {/* <div className={`${styles.top_bar_right_section}`}>
              <div>Turn Off ACs</div>
              <div>
                <div className={`${styles.top_bar_switch}`}>
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {element}
      </div>
    </div>
  );
}

export default AcDashboard;
